exports.userConstants = require("./user");
exports.alertConstants = require("./alert");
exports.termConstants = require("./terms");
exports.payoutConstants = require("./payout");
exports.supportConstants = require("./support");
exports.settingConstants = require("./settings");
exports.orderConstants = require("./order");
exports.reportConstants = require("./reports");
exports.disputeConstants = require("./dispute");
exports.deliveryConstants = require("./deliveryMan");
exports.transactionConstants = require("./transaction");

const {
  REACT_APP_BASE_URL: BASE_URL,
  REACT_APP_BASE_URL_ADDRESS: BASE_URL_ADDRESS,
  REACT_APP_URL: APP_URL,
  REACT_APP_ASSET_URL: ASSET_URL,
  REACT_APP_WEBSITE_URL: WEBSITE_URL,
  REACT_APP_RECAPTCHA_SITE_KEY: RECAPTCHA_SITE_KEY,
} = process.env;

exports.BASE_URL = BASE_URL;
exports.APP_URL = APP_URL;
exports.ASSET_URL = ASSET_URL;
exports.WEBSITE_URL = WEBSITE_URL;
exports.BASE_URL_ADDRESS = BASE_URL_ADDRESS;
exports.RECAPTCHA_SITE_KEY = RECAPTCHA_SITE_KEY;

exports.LOCALSTORAGE_TOKEN_KEY = "user";
exports.LOCALSTORAGE_ROLE = "trust_user_type";
exports.USER_ROLE_SELLER = "Seller";
exports.USER_ROLE_MARKET = "MarketPlace";

exports.headers = {
  "Access-Control-Allow-Origin": "*",
  Authorization: "Bearer " + localStorage.getItem("user"),
  "Content-Type": "application/json",
};
