import React, { Component } from "react";
import { alertActions } from "../actions";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import { Link, withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";

const initialState = {
  orderCode: "",
  submitted: false,
};

class Pod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      email: "",
      otp: "",
      phoneNumber: "",
      orderCode: "",
      response: "",
      submitted: false,
      sample: true,
    };
  }

  componentDidMount = () => {
    this.props.logout("none", this.props.history);
  };
  componentWillReceiveProps = (props) => {
    //console.log("props:"+JSON.stringify(props))
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type == "alert-danger"
    ) {
      swal("Error", props.alert.message, "error").then(() => {
        this.props.dispatch(alertActions.alert.clear());
      });
    }
    if (
      props.alert &&
      props.alert.message &&
      props.alert.type == "alert-success"
    ) {
      swal("Success", props.alert.message, "success").then(() => {
        this.props.dispatch(alertActions.alert.clear());
      });
    }
  };
  handleTabClick = (type) => {
    const { dispatch } = this.props;
    this.setState(initialState);
    dispatch(alertActions.alert.clear());
    // this.props.history.push(`/forgot/${type}`);
    this.setState({ type: type });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  hideAlert = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.alert.clear());
  };

  handlecall = (types) => {
    this.setState({ submitted: true });
    const { orderCode, phoneNumber, otp, response } = this.state;
    const { orderNo, phone } = this.props.match.params;
    let data = {};
    let type = types;
    const { dispatch } = this.props;
    switch (type) {
      case "completeorder":
        data = { order_code: orderNo };
        this.props.processPod(type, data, this.props.history);
        break;
      case "buyeraccept":
        data = { answer: "Y", state: 1, order_code: orderNo };
        this.props.processPod(type, data, this.props.history);
        break;
      case "inspections":
        data = {
          answer: "T",
          state: 1,
          order_code: orderNo,
          type: "inspections",
        };
        this.props.processPod(type, data, this.props.history);
        break;
      case "inspection":
        data = {
          answer: "T",
          state: 1,
          order_code: orderNo,
          type: "inspection",
        };
        this.props.processPod(type, data, this.props.history);
        break;
      default:
        break;
    }
    this.setState({ sample: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { orderCode, phoneNumber, otp, response, reject } = this.state;
    const { orderNo, phone } = this.props.match.params;
    let data = {};
    let type = e.target.dataset.type;
    const { dispatch } = this.props;
    console.log(this.state);
    console.log(type, "type");
    console.log(response, "response");
    switch (type) {
      case "pod":
        data = { phone_number: phoneNumber, order_code: orderCode };
        this.props.processPod(type, data, this.props.history);
        break;
      case "deliveryOTP":
        data = { otp: otp, order_code: orderNo };
        this.props.processPod(type, data, this.props.history);
        break;
      case "buyer":
        data = { answer: response, state: 1, order_code: orderNo };
        this.props.processPod(type, data, this.props.history);
        break;
      case "inspection":
        data = {
          answer: response,
          state: 2,
          order_code: orderNo,
          type: "inspection",
        };
        this.props.processPod(type, data, this.props.history);
        break;
      case "inspections":
        data = {
          answer: response,
          state: 1,
          order_code: orderNo,
          type: "inspections",
        };
        this.props.processPod(type, data, this.props.history);
        break;
      case "buyerreject":
        data = { answer: "N", state: 1, order_code: orderNo, reject: reject };
        // return console.log("history", orderNo, this.props.history);
        this.props.processPod(type, data, this.props.history);
        break;
      case "buyerreplacement":
        data = { answer: "N", state: 1, order_code: orderNo, reject: reject };
        this.props.processPod("buyerreject", data, this.props.history);
        break;
      default:
        break;
    }
  };

  resendOTP = (e) => {
    e.preventDefault();
    const { orderNo, phone } = this.props.match.params;
    const type = "pod";
    const data = { phone_number: phone, order_code: orderNo, resend: 1 };
    this.props.processPod(type, data, this.props.history);
  };

  handlePhone = (value, name) => {
    // value = value.replace(/[^a-zA-Z ]/g, "");
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    const { sending, alert, loading } = this.props;
    const { email, otp, phoneNumber, orderCode, response, phone } = this.state;
    const { type, orderNo } = this.props.match.params;
    console.log(this.props, "Porpsss");
    console.log("type", type);

    if (
      this.props.match.params.type === "buyeraccept" &&
      this.state.sample === true
    ) {
      const type = this.props.match.params.type;
      this.handlecall(type);
    }
    if (
      this.props.match.params.type === "completeorder" &&
      this.state.sample === true
    ) {
      const type = this.props.match.params.type;
      this.handlecall(type);
    }
    if (
      this.props.match.params.type === "inspections" &&
      this.state.sample === true
    ) {
      const type = this.props.match.params.type;
      this.handlecall(type);
    }
    if (
      this.props.match.params.type === "inspection" &&
      this.state.sample === true
    ) {
      const type = this.props.match.params.type;
      this.handlecall(type);
    }

    return (
      <div className="background-gradient" style={{ height: "100vh" }}>
        <nav class="navbar navbar-expand-lg fixed-top navbar-inverse">
          <a class="navbar-brand" href="https://www.atarapay.com">
            <img
              src="/dist/img/logo-header.png"
              alt=""
              className="logo-img img img-responsive"
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </nav>

        <div class="row row-margin top-margin-60">
          <div className="col-sm-3 col-md-3"></div>
          <div className="col-sm-8 col-md-6 col-12">
            <div className="card card-margin">
              <div class="tab-content">
                <div
                  role="tabpanel"
                  class={`tab-pane  ${!type ? `active` : ``}`}
                  id="pod"
                >
                  <br />
                  <form data-type="pod" onSubmit={this.handleSubmit}>
                    <h5>
                      <strong>Point of Delivery (PoD)</strong>
                    </h5>
                    <div class="form-group">
                      <label htmlFor="buyer_email">
                        <small>Please enter your phone number*</small>
                      </label>
                      <div class="col-sm-11">
                        <PhoneInput
                          defaultCountry="NG"
                          placeholder="08XX XXX XXXX"
                          limitMaxLength={true}
                          value={
                            phone &&
                            formatPhoneNumber(phone && phone, "National")
                          }
                          onChange={(value) =>
                            this.handlePhone(
                              (value &&
                                formatPhoneNumber(
                                  value,
                                  "International"
                                ).replace(/ /g, "")) ||
                                "",
                              "phoneNumber"
                            )
                          }
                          inputClassName={"custom-input max-custom-input3"}
                        />
                      </div>{" "}
                    </div>
                    <div class="form-group">
                      <label for="order_code">
                        <small>Please enter order code*</small>
                      </label>
                      <input
                        type="text"
                        name="orderCode"
                        value={orderCode}
                        onChange={this.handleChange}
                        v-model="order_code"
                        id="order_code"
                        class="custom-input max-custom-input2"
                        required="required"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        disabled={loading}
                        value={loading ? " Sending..." : "Submit"}
                        class="btn btn-dark-blue form-control btn-customer-forgot-password"
                      />
                    </div>
                    <hr />
                  </form>
                </div>
                <div
                  role="tabpanel"
                  class={`tab-pane   ${
                    type && type
                      ? type && type === `deliveryOTP`
                        ? `active`
                        : ``
                      : ``
                  }`}
                  id="seller"
                >
                  <br />
                  <form data-type="deliveryOTP" onSubmit={this.handleSubmit}>
                    <h4>
                      <strong>Point of Delivery</strong>
                    </h4>

                    <div class="custom-label form-group">
                      <label for="seller_email">
                        <small>Please enter your OTP*</small>
                      </label>
                      <input
                        type="text"
                        name="otp"
                        value={otp}
                        onChange={this.handleChange}
                        v-model="otp"
                        id="otp"
                        class="form-control"
                        required="required"
                      />
                      <a onClick={this.resendOTP} href="#" class="text-primary">
                        <p class="float-left">
                          <em>
                            <small>Resend OTP?</small>
                          </em>
                        </p>
                      </a>
                    </div>

                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        disabled={loading}
                        value={loading ? " Sending..." : "Submit"}
                        class="btn btn-dark-blue form-control btn-seller-forgot-password"
                      />
                    </div>
                    <hr />
                  </form>
                </div>
                <div
                  role="tabpanel"
                  class={`tab-pane  ${
                    type && type
                      ? type && type === `buyer`
                        ? `active`
                        : ``
                      : ``
                  }`}
                  id="buyer_response"
                >
                  <br />
                  <form data-type="buyer" onSubmit={this.handleSubmit}>
                    <h5>
                      <strong>Point of Delivery</strong>
                    </h5>
                    {alert && alert.message && (
                      <div
                        class={`alert animated bounceIn buyer-alert ${alert.type}`}
                      >
                        <a aria-label="close" class="close alert-close">
                          ×
                        </a>
                        <span class="alert-message-content">
                          {alert.message}
                        </span>
                      </div>
                    )}
                    <div class="form-group">
                      <label for="response">
                        <small>Please select response*</small>
                      </label>
                      <select
                        name="response"
                        id="response"
                        value={response}
                        onChange={this.handleChange}
                        class="form-control"
                      >
                        <option value="">Select Response</option>
                        <option value="Y">Accept</option>
                        <option value="N">Reject</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        disabled={loading}
                        value={loading ? " Sending..." : "Submit"}
                        class="btn btn-dark-blue form-control"
                      />
                    </div>
                    <hr />
                  </form>
                </div>
                <div
                  role="tabpanel"
                  class={`tab-pane  ${
                    type && type
                      ? type && type === `buyerreject`
                        ? `active`
                        : ``
                      : ``
                  }`}
                  id="buyer_response"
                >
                  <br />
                  <form data-type="buyerreject" onSubmit={this.handleSubmit}>
                    <h5>
                      <strong>Point of Delivery</strong>
                    </h5>
                    {alert && alert.message && (
                      <div
                        class={`alert animated bounceIn buyer-alert ${alert.type}`}
                      >
                        <a aria-label="close" class="close alert-close">
                          ×
                        </a>
                        <span class="alert-message-content">
                          {alert.message}
                        </span>
                      </div>
                    )}
                    <div class="form-group">
                      <label for="response">
                        <small>Please select response*</small>
                      </label>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="Seller has not delivered the goods or service(s)"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          {" "}
                          Seller has not delivered the goods or service(s)
                        </div>
                      </div>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="Wrong item size or wrong goods or service(s) delivered"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          {" "}
                          Wrong item size or wrong goods or service(s) delivered
                        </div>
                      </div>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="The goods were found damaged/ broken/ stolen"
                            onChange={this.handleChange}
                          />{" "}
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          The goods were found damaged/ broken/ stolen
                        </div>
                      </div>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="Goods or service(s) didn't match the website or catalog description"
                            onChange={this.handleChange}
                          />{" "}
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          Goods or service(s) didn't match the website or
                          catalog description
                        </div>
                      </div>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="I changed my mind (This may incur cancellation fee)"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          I changed my mind (This may incur cancellation fee)
                        </div>
                      </div>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="I ordered the wrong thing (This may incur cancellation fee)"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          I ordered the wrong thing (This may incur cancellation
                          fee)
                        </div>
                      </div>
                      <br />
                    </div>
                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        disabled={loading}
                        value={loading ? " Sending..." : "Submit"}
                        class="btn btn-dark-blue form-control"
                      />
                    </div>
                    <hr />
                  </form>
                </div>
                <div
                  role="tabpanel"
                  class={`tab-pane  ${
                    type && type
                      ? type && type === `buyerreplacement`
                        ? `active`
                        : ``
                      : ``
                  }`}
                  id="buyer_response"
                >
                  <br />
                  <form
                    data-type="buyerreplacement"
                    onSubmit={this.handleSubmit}
                  >
                    <h5>
                      <strong>Point of Delivery</strong>
                    </h5>
                    {alert && alert.message && (
                      <div
                        class={`alert animated bounceIn buyer-alert ${alert.type}`}
                      >
                        <a aria-label="close" class="close alert-close">
                          ×
                        </a>
                        <span class="alert-message-content">
                          {alert.message}
                        </span>
                      </div>
                    )}
                    <div class="form-group">
                      <label for="response">
                        <small>Please select response*</small>
                      </label>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="Seller has not delivered the goods or service(s)"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          {" "}
                          Seller has not delivered the goods or service(s)
                        </div>
                      </div>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="Wrong item size or wrong goods or service(s) delivered"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          {" "}
                          Wrong item size or wrong goods or service(s) delivered
                        </div>
                      </div>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="The goods were found damaged/ broken/ stolen"
                            onChange={this.handleChange}
                          />{" "}
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          The goods were found damaged/ broken/ stolen
                        </div>
                      </div>
                      <br />
                      <div>
                        <div style={{ float: "left", width: "10%" }}>
                          <input
                            type="radio"
                            name="reject"
                            value="Goods or service(s) didn't match the website or catalog description"
                            onChange={this.handleChange}
                          />{" "}
                        </div>
                        <div style={{ float: "left", width: "90%" }}>
                          Goods or service(s) didn't match the website or
                          catalog description
                        </div>
                      </div>
                      <br />
                    </div>
                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        disabled={loading}
                        value={loading ? " Sending..." : "Submit"}
                        class="btn btn-dark-blue form-control"
                      />
                    </div>
                    <hr />
                  </form>
                </div>
                {/*<div role="tabpanel" class={`tab-pane  ${type && type ? (type && type === `buyeraccept` ? `active` : ``) : ``}`} id="buyer_response">
                                    <br />
                                    <form data-type="buyeraccept" onSubmit={this.handleSubmit}>
                                        <h5><strong>Point of Delivery</strong></h5>
                                        {alert && alert.message && <div class={`alert animated bounceIn buyer-alert ${alert.type}`}><a aria-label="close" class="close alert-close">×</a>
                                            <span class="alert-message-content">{alert.message}</span></div>}
                                        <div class="form-group">
                                            <label><small>Please select response</small></label><br />
                                        </div>
                                        <div class="form-group">
                                            <input type="submit" name="button"  disabled={loading} value={loading ? " Sending..." : "Submit"} class="btn btn-dark-blue form-control" />
                                        </div>
                                        <hr />
                                    </form>
                                </div>*/}

                <div
                  role="tabpanel"
                  class={`tab-pane  ${
                    type && type
                      ? type && type === `inspection`
                        ? `active`
                        : ``
                      : ``
                  }`}
                  id="inspection"
                >
                  <br />
                  <form data-type="inspection" onSubmit={this.handleSubmit}>
                    <h5>
                      <strong>Point of Delivery</strong>
                    </h5>
                    {alert && alert.message && (
                      <div
                        class={`alert animated bounceIn buyer-alert ${alert.type}`}
                      >
                        <a aria-label="close" class="close alert-close">
                          ×
                        </a>
                        <span class="alert-message-content">
                          {alert.message}
                        </span>
                      </div>
                    )}
                    <div class="form-group">
                      <label for="response">
                        <small>
                          Please confirm you are in possession of the item*
                        </small>
                      </label>
                      <select
                        name="response"
                        value={response}
                        onChange={this.handleChange}
                        id="response"
                        class="form-control"
                      >
                        <option value="">Select Response</option>
                        <option value="Y">Yes (Order is ok)</option>
                        <option value="N">No (Order is not ok)</option>
                        <option value="A">
                          Buyer and I have agreed to another delivery attempt
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        disabled={loading}
                        value={loading ? " Sending..." : "Submit"}
                        class="btn btn-dark-blue form-control"
                      />
                    </div>
                    <hr />
                  </form>
                </div>

                <div
                  role="tabpanel"
                  class={`tab-pane  ${
                    type && type
                      ? type && type === `inspections`
                        ? `active`
                        : ``
                      : ``
                  }`}
                  id="inspections"
                >
                  <br />
                  <form data-type="inspections" onSubmit={this.handleSubmit}>
                    <h5>
                      <strong>Point of Delivery</strong>
                    </h5>
                    {alert && alert.message && (
                      <div
                        class={`alert animated bounceIn buyer-alert ${alert.type}`}
                      >
                        <a aria-label="close" class="close alert-close">
                          ×
                        </a>
                        <span class="alert-message-content">
                          {alert.message}
                        </span>
                      </div>
                    )}
                    <div class="form-group">
                      <label for="response">
                        <small>
                          Please confirm you are in possession of the item*
                        </small>
                      </label>
                      <select
                        name="response"
                        value={response}
                        onChange={this.handleChange}
                        id="response"
                        class="form-control"
                      >
                        <option value="">Select Response</option>
                        <option value="Y">Yes (Order is ok)</option>
                        <option value="N">No (Order is not ok)</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <input
                        type="submit"
                        name="button"
                        disabled={loading}
                        value={loading ? " Sending..." : "Submit"}
                        class="btn btn-dark-blue form-control"
                      />
                    </div>
                    <hr />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Pod);
