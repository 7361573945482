import { orderService } from '../service.js';
import {
    orderConstants,
    disputeConstants,
    transactionConstants,
} from '../constants';
import { alertActions, userActions } from './';

const view = (type, history) => {
    return (dispatch) => {
        dispatch(request());
        orderService.order
            .get(type)
            .then((res) => {
                dispatch(setStatus(res?.status));
                if (res.data.status === 'success') {
                    if (type === 'customer') {
                        dispatch(
                            success(
                                res?.data?.data?.orders?.map((data) => ({
                                    ...data,
                                    extra: res?.data?.data?.config,
                                }))
                            )
                        );
                    } else {
                        dispatch(success(res?.data?.data));
                    }
                } else {
                    dispatch(failure(res.data.message));
                    // dispatch(alertActions.alert.error((res.data.message)?.replace(/_/g, " ")));
                }
            })
            .catch((err) => {
                dispatch(setStatus(err?.response?.status));
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response?.data?.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function setStatus(status) {
        return {type: orderConstants.order.SET_STATUS, status};
    }

    function request() {
        return {type: orderConstants.order.ORDER_DETAILS_REQUEST};
    }

    function success(orders) {
        return {type: orderConstants.order.ORDER_DETAILS_SUCCESS, orders};
    }

    function failure(error) {
        return {type: orderConstants.order.ORDER_DETAILS_FAILURE, error};
    }
};

const viewPayments = (type, history) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order
            .getPaymentHistory(type)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(res.data.data));
                } else {
                    dispatch(failure(res.data.message));
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: orderConstants.order.PAY_DETAILS_REQUEST};
    }

    function success(payments) {
        return {type: orderConstants.order.PAY_DETAILS_SUCCESS, payments};
    }

    function failure(error) {
        return {type: orderConstants.order.PAY_DETAILS_FAILURE, error};
    }
};

const raiseDispute = (type, data, history) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order
            .raiseDispute(type, data)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            'Dispute raised successfully'?.replace(/_/g, ' ')
                        )
                    );
                } else {
                    dispatch(failure(res.data.message));

                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, ' '))
                    );
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: disputeConstants.dispute.RAISE_DISPUTE_REQUEST};
    }

    function success(disputes) {
        return {type: disputeConstants.dispute.RAISE_DISPUTE_SUCCESS, disputes};
    }

    function failure(error) {
        return {type: disputeConstants.dispute.RAISE_DISPUTE_FAILURE, error};
    }
};

const cancelDispute = (type, data, history) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order
            .cancelDispute(type, data)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            'Dispute canceled successfully'?.replace(/_/g, ' ')
                        )
                    );

                    if (history.location.pathname === '/app/disputes/all')
                        history.push('/app/disputes/all');
                    else history.push('/app/orders');
                } else {
                    dispatch(failure(res.data.message));

                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, ' '))
                    );
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status == 401) {
                        dispatch(userActions.user.logout(type, history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: disputeConstants.dispute.CANCEL_DISPUTE_REQUEST};
    }

    function success(disputes) {
        return {type: disputeConstants.dispute.CANCEL_DISPUTE_SUCCESS, disputes};
    }

    function failure(error) {
        return {type: disputeConstants.dispute.CANCEL_DISPUTE_FAILURE, error};
    }
};

const cancelOrderRequest = () => ({
    type: transactionConstants.transaction.CANCEL_TRANSACTION,
});
const cancelOrderRequestSuccess = (order) => ({
    type: transactionConstants.transaction.CANCEL_TRANSACTION_SUCCESS,
    order,
});
const cancelOrderRequestFailure = (error) => ({
    type: transactionConstants.transaction.CANCEL_TRANSACTION_FAILURE,
    error,
});
const cancelOrder = (type, response, history) => (dispatch) => {
    dispatch(cancelOrderRequest());
    orderService.order
        .cancelOrder(type, response)
        .then((res) => res.data)
        .then((res) => {
            if (res.status === 'success') {
                dispatch(
                    cancelOrderRequestSuccess({
                        ...res.data,
                        extra: JSON.parse(res.data.extra),
                    })
                );
                dispatch(alertActions.alert.success(res.message));
            } else {
                dispatch(cancelOrderRequestFailure(res.message));
                dispatch(alertActions.alert.error(res.message));
            }
        })
        .catch((err) => {
            if (err?.response) {
                if (err?.response.status === 401) {
                    dispatch(userActions.user.logout(type, history));
                    dispatch(cancelOrderRequestFailure(err?.response.data.message));
                    dispatch(
                        alertActions.alert.error(
                            err?.response.data.message?.replace(/_/g, ' ')
                        )
                    );
                } else {
                    dispatch(cancelOrderRequestFailure(err?.response.data.message));
                    dispatch(
                        alertActions.alert.error(
                            err?.response.data.message?.replace(/_/g, ' ')
                        )
                    );
                }
            } else if (err?.request) {
                dispatch(cancelOrderRequestFailure(err?.message));
                dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
            } else {
                dispatch(cancelOrderRequestFailure(err?.message));
                dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
            }
        });
};

const assignDispatcher = (type, data, history) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order
            .assignDispatcher(type, data)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(res.data.message?.replace(/_/g, ' '))
                    );
                } else {
                    dispatch(failure(res.data.message));
                    if (
                        res.data.message.includes('The selected delivery man id is invalid')
                    ) {
                        dispatch(
                            alertActions.alert.error(
                                'Personnel was assigned previously. please select another personnel if you want to re-assign'?.replace(
                                    /_/g,
                                    ' '
                                )
                            )
                        );
                    } else {
                        dispatch(
                            alertActions.alert.error(res.data.message?.replace(/_/g, ' '))
                        );
                    }
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: orderConstants.order.ASSIGN_DELIVERY_MAN};
    }

    function success(delivery) {
        return {type: orderConstants.order.ASSIGN_DELIVERY_MAN_SUCCESS, delivery};
    }

    function failure(error) {
        return {type: orderConstants.order.ASSIGN_DELIVERY_MAN_FAILURE, error};
    }
};

const viewDisputes = (type, history) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order
            .getDisputes(type)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(res.data.data));
                } else {
                    dispatch(failure(res.data.message));
                    if (res.data.message === 'no_dispute') {
                    } else {
                        dispatch(
                            alertActions.alert.error(res.data.message?.replace(/_/g, ' '))
                        );
                    }
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }

                //TODO: Add error tracking event
            });
    };

    function request() {
        return {type: disputeConstants.dispute.ORDER_DISPUTES_REQUEST};
    }

    function success(disputes) {
        return {type: disputeConstants.dispute.ORDER_DISPUTES_SUCCESS, disputes};
    }

    function failure(error) {
        return {type: disputeConstants.dispute.ORDER_DISPUTES_FAILURE, error};
    }
};

const verifyPromo = (data, role, history) => {
    return (dispatch) => {
        if (!data.promo_code) {
            return dispatch(alertActions.alert.error('Enter promo code'));
        }
        dispatch(request());
        orderService.order
            .applyCoupon(data)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(res.data.data));
                    dispatch(alertActions.alert.success('Promo code applied'));
                } else {
                    dispatch(failure(res.data.message));
                    if (res.data.message.includes('promo_code_invalid')) {
                        dispatch(alertActions.alert.error('Invalid promo code'));
                    } else if (res.data.message.includes('promo_code_already_expired')) {
                        dispatch(
                            alertActions.alert.error('Promo code has already expired')
                        );
                    } else {
                        dispatch(
                            alertActions.alert.error(res.data.message?.replace(/_/g, ' '))
                        );
                    }
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout('buyer', history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: orderConstants.order.VERIFY_PROMO_REQUEST};
    }

    function success(promo) {
        return {type: orderConstants.order.VERIFY_PROMO_SUCCESS, promo};
    }

    function failure(error) {
        return {type: orderConstants.order.VERIFY_PROMO_FAILURE, error};
    }
};

const clearPromo = (data, role, history) => {
    return (dispatch) => {
        dispatch(request());
        orderService.order
          .clearCoupon(data)
          .then((res) => {
              if (res.data.status === 'success') {
                  dispatch(clear(res.data.data));
              } else {
                  dispatch(failure(res.data.message));
                  dispatch(alertActions.alert.error('ERROR OCCURRED PLEASE TRY AGAIN'));
              }
          })
          .catch((err) => {
              if (err?.response) {
                  if (err?.response.status === 401) {
                      dispatch(userActions.user.logout('buyer', history));
                      dispatch(failure(err?.response.data.message));
                      dispatch(
                        alertActions.alert.error(
                          err?.response.data.message?.replace(/_/g, ' ')
                        )
                      );
                  } else {
                      dispatch(failure(err?.response.data.message));
                      dispatch(
                        alertActions.alert.error(
                          err?.response.data.message?.replace(/_/g, ' ')
                        )
                      );
                  }
              } else if (err?.request) {
                  dispatch(failure(err?.message));
                  dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
              } else {
                  dispatch(failure(err?.message));
                  dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
              }
          });
    };

    function request() {
        return {type: orderConstants.order.CLEAR_PROMO_REQUEST};
    }

    function clear(promo) {
        return {type: orderConstants.order.CLEAR_PROMO_SUCCESS, promo};
    }
    function failure(error) {
        return {type: orderConstants.order.CLEAR_PROMO_FAILURE, error};
    }

};

const processPayment = (data, history, paymentType) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order
            .processPayment(data, paymentType)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(data));
                    if (res.data.message === 'order_created_payment_successful') {
                        if (res.data.data.redirectUrl){
                            window.location.href = res.data.data.redirectUrl;
                        }
                        if (res.data.data.redirect){
                            window.location.href = res.data.data.redirect;
                        }

                    }
                    if (res.data.message === 'bvn_payment_successful') {
                    }
                } else {
                    dispatch(failure(res.data.message));
                    if (res.data.message === 'payment_already_processed') {
                        dispatch(
                            alertActions.alert.error(
                                'This payment has already been processed and your order has been created before now. Please check that you have not visited this link before. Thank you'?.replace(
                                    /_/g,
                                    ' '
                                )
                            )
                        );
                    } else {
                        dispatch(
                            alertActions.alert.error(
                                'Oops! Something went wrong. Payment was not successful and order was not created. Please try again later.'?.replace(
                                    /_/g,
                                    ' '
                                )
                            )
                        );
                    }
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout('buyer', history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: orderConstants.order.PROCESS_PAYMENT_REQUEST};
    }

    function success(payment) {
        return {
            type: orderConstants.order.PROCESS_PAYMENT_SUCCESS,
            payment,
        };
    }

    function failure(error) {
        return {type: orderConstants.order.PROCESS_PAYMENT_FAILURE, error};
    }
};
const cancelPayment = (data, history, paymentType) => {
    return (dispatch) => {
        dispatch(request());
        orderService.order
            .cancelPayment(data, paymentType)
            .then((res) => {
                if (res.data.status === 'success') {
                    window.location.href = res.data.data.redirect;
                } else {
                    dispatch(failure(res.data.message));
                    if (res.data.message === 'payment_already_processed') {
                        dispatch(
                            alertActions.alert.error(
                                'This payment has already been processed and your order has been created before now. Please check that you have not visited this link before. Thank you'?.replace(
                                    /_/g,
                                    ' '
                                )
                            )
                        );
                    } else {
                        dispatch(
                            alertActions.alert.error(
                                'Oops! Something went wrong. Payment was not successful and order was not created. Please try again later.'?.replace(
                                    /_/g,
                                    ' '
                                )
                            )
                        );
                    }
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout('buyer', history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: orderConstants.order.PROCESS_PAYMENT_REQUEST};
    }

    function success(payment) {
        return {type: orderConstants.order.PROCESS_PAYMENT_SUCCESS, payment};
    }

    function failure(error) {
        return {type: orderConstants.order.PROCESS_PAYMENT_FAILURE, error};
    }
};

const processPod = (type, data, history) => {
    return (dispatch) => {
        let path;
        const startTranxURL = 'startTransaction';
        const verifyTranxURL = 'verifyTransaction';
        const responseTranxURL = 'response';
        dispatch(request());
        if (type === 'pod') {
            path = startTranxURL;
        }

        switch (type) {
            case 'pod':
                path = startTranxURL;
                break;
            case 'deliveryOTP':
                path = verifyTranxURL;
                break;
            case 'buyer':
            case 'inspection':
                path = responseTranxURL;
                break;
            case 'inspections':
                path = responseTranxURL;
                break;
            case 'buyeraccept':
                path = 'accept';
                break;
            case 'buyerreject':
                path = 'reject';
                break;
            case 'completeorder':
                path = 'complete';
                break;
            default:
                break;
        }
        orderService.order
            .processPod(path, data)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(res.data.data));
                    if (type === 'pod' && res.data.message === 'verification_sent') {
                        dispatch(
                            alertActions.alert.success(
                                'An OTP code has been sent to your email and phone number for confirmation'
                            )
                        );
                        history.push(
                            `/pod/deliveryOTP/${data.order_code}/${data.phone_number}`
                        );
                    } else if (
                        res.data.message === 'pod_created' &&
                        (type === 'deliveryOTP' || type === 'completeorder')
                    ) {
                        history.push(`/login`);
                        dispatch(
                            alertActions.alert.success(
                                'Notification has been sent to ' +
                                res.data.data.email +
                                ' and ' +
                                res.data.data.phone +
                                ' to accept or reject the order'
                            )
                        );
                    } else if (res.data.message === 'pod_updated' && type === 'buyer') {
                        history.push(`/login`);
                        if (data.answer === 'Y')
                            dispatch(
                                alertActions.alert.success(
                                    'Thank you, your feedback has been received and seller will be credited'
                                )
                            );
                        else
                            dispatch(
                                alertActions.alert.success(
                                    'Thank you, SMS and email has been sent to delivery personnel for confirmation'
                                )
                            );
                    } else if (
                        res.data.message === 'pod_updated' &&
                        type === 'inspection'
                    ) {
                        history.push(`/login`);
                        if (data.answer === 'A')
                            dispatch(
                                alertActions.alert.success(
                                    'Thank you for your response. Buyer will be notified of the new delivery attempt'
                                )
                            );
                        else if (data.answer === 'Y')
                            dispatch(
                                alertActions.alert.success(
                                    'Thank you for your response. Buyer will be refunded'
                                )
                            );
                        else if (data.answer === 'N')
                            dispatch(
                                alertActions.alert.success(
                                    'Thank you for your response. Buyer will be notified'
                                )
                            );
                        else
                            dispatch(
                                alertActions.alert.success(
                                    'Point of Delivery transaction completed'
                                )
                            );
                    } else if (
                        res.data.message === 'pod_updated' &&
                        type === 'inspections'
                    ) {
                        history.push(`/login`);
                        if (data.answer === 'Y')
                            dispatch(
                                alertActions.alert.success(
                                    'Thank you for your response. Buyer will be refunded'
                                )
                            );
                        else if (data.answer === 'N')
                            dispatch(
                                alertActions.alert.success(
                                    'Thank you for your response. Buyer will be notified'
                                )
                            );
                        else
                            dispatch(
                                alertActions.alert.success(
                                    'Point of Delivery transaction completed'
                                )
                            );
                    } else if (res.data.message === 'pod_updated') {
                        history.push(`/login`);
                        dispatch(
                            alertActions.alert.success('Thank you for your response.')
                        );
                    } else if (
                        res.data.message === 'not_submitted' &&
                        type === 'inspections'
                    ) {
                        //dispatch(alertActions.alert.success(("Thank you for your response dasda.")));
                    } else if (
                        res.data.message === 'not_submitted' &&
                        type === 'inspection'
                    ) {
                        //dispatch(alertActions.alert.success(("Thank you for your response dasda.")));
                    } else {
                        dispatch(
                            alertActions.alert.success(res.data.message?.replace(/_/g, ' '))
                        );
                    }
                } else {
                    dispatch(failure(res.data.message));
                    if (res.data.message === 'delivery_man_unknown') {
                        dispatch(
                            alertActions.alert.error(
                                'No delivery personnel is assigned to this order. Contact seller to assign order.'
                            )
                        );
                    } else if (res.data.message === 'verification_failed') {
                        dispatch(
                            alertActions.alert.error(
                                'Verification failed. Please enter OTP correctly or click “Resend OTP�? to verify'
                            )
                        );
                    } else if (res.data.message === 'session_complete_waiting')
                        dispatch(
                            alertActions.alert.error(
                                'This order code has already been used and the point of delivery transaction is currently awaiting the buyer’s response. Inform buyer to respond or enter a valid phone number and order code and try again.'
                            )
                        );
                    else if (res.data.message === 'transaction_completed_before')
                        dispatch(
                            alertActions.alert.error(
                                'This order code has already been used and the point of delivery transaction is currently completed. Please enter a valid phone number and order code and try again.'
                            )
                        );
                    else if (res.data.message === 'invalid_order_code')
                        dispatch(
                            alertActions.alert.error(
                                'Invalid phone number or order code. Please enter a valid phone number and order code and try again.'
                            )
                        );
                    else if (
                        res.data.message === 'invalid_otp' ||
                        res.data.message === 'verification_failed'
                    )
                        dispatch(
                            alertActions.alert.error(
                                'Verification failed. Please enter OTP correctly or click “Resend OTP�? to verify.'
                            )
                        );
                    else if (
                        res.data.message === 'already_submitted' &&
                        type === 'inspections'
                    ) {
                        history.push(`/login`);
                        dispatch(
                            alertActions.alert.error(
                                'Order response has been already submitted'
                            )
                        );
                    } else if (
                        res.data.message === 'already_submitted' &&
                        type === 'inspection'
                    ) {
                        history.push(`/login`);
                        dispatch(
                            alertActions.alert.error(
                                'Order response has been already submitted'
                            )
                        );
                    } else {
                        dispatch(
                            alertActions.alert.error(res.data.message?.replace(/_/g, ' '))
                        );
                    }
                }
            })
            .catch((err) => {
                if (type === 'deliveryOTP') {
                    dispatch(
                        alertActions.alert.error(
                            'This order code has already been used and the point of delivery transaction is currently awaiting the buyer’s response. Inform buyer to respond or enter a valid phone number and order code and try again.'?.replace(
                                /_/g,
                                ' '
                            )
                        )
                    );
                    dispatch(failure(err?.message));
                } else if (type === 'buyer' || type === 'inspection') {
                    dispatch(
                        alertActions.alert.error(
                            'Your session has already been completed'?.replace(/_/g, ' ')
                        )
                    );
                    dispatch(failure(err?.message));
                } else {
                    if (err?.response) {
                        if (err?.response.status === 401) {
                            dispatch(failure(err?.response.data.message));
                            dispatch(
                                alertActions.alert.error(
                                    err?.response.data.message?.replace(/_/g, ' ')
                                )
                            );
                        } else {
                            dispatch(failure(err?.response.data.message));
                            dispatch(
                                alertActions.alert.error(
                                    err?.response.data.message?.replace(/_/g, ' ')
                                )
                            );
                        }
                    } else if (err?.request) {
                        dispatch(failure(err?.message));
                        dispatch(
                            alertActions.alert.error(err?.message?.replace(/_/g, ' '))
                        );
                    } else {
                        dispatch(failure(err?.message));
                        dispatch(
                            alertActions.alert.error(err?.message?.replace(/_/g, ' '))
                        );
                    }
                }
            });
    };

    function request() {
        return {type: orderConstants.order.START_POD};
    }

    function success(delivery) {
        return {type: orderConstants.order.START_POD_SUCCESS, delivery};
    }

    function failure(error) {
        return {type: orderConstants.order.START_POD_FAILURE, error};
    }
};

const completeOrder = (data) => {
    return (dispatch) => {
        orderService.order.completeOrder(data).then((res) => {
            dispatch(success(res.data.data));
        });
    };

    function request() {
        return {type: orderConstants.order.START_POD};
    }

    function success(delivery) {
        return {type: orderConstants.order.START_POD_SUCCESS, delivery};
    }

    function failure(error) {
        return {type: orderConstants.order.START_POD_FAILURE, error};
    }
};

const addServiceProvider = (data, history) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order
            .addServiceProvider(data)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(res.data.data));
                    dispatch(
                        alertActions.alert.success(
                            'Service Provider assigned successfully'?.replace(/_/g, ' ')
                        )
                    );
                    history.push('/app/orders');
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, ' '))
                    );
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout('seller', history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: orderConstants.order.PROCESS_PAYMENT_REQUEST};
    }

    function success(payment) {
        return {type: orderConstants.order.PROCESS_PAYMENT_SUCCESS, payment};
    }

    function failure(error) {
        return {type: orderConstants.order.PROCESS_PAYMENT_FAILURE, error};
    }
};

const getTransactionPaymentInformation = (type, data, history) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order
            .getTransactionPaymentInformation(type, data)
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(success(res.data.data));
                } else {
                    dispatch(failure(res.data.message));
                    dispatch(
                        alertActions.alert.error(res.data.message?.replace(/_/g, ' '))
                    );
                }
            })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout(type, history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: orderConstants.order.GET_TRANSACTION_PAYMENT_REQUEST};
    }

    function success(payment) {
        return {
            type: orderConstants.order.GET_TRANSACTION_PAYMENT_SUCCESS,
            payment,
        };
    }

    function failure(error) {
        return {
            type: orderConstants.order.GET_TRANSACTION_PAYMENT_FAILURE,
            error,
        };
    }
};

const clearTransactionPaymentInformation = () => {
    return (dispatch) => {
        dispatch(request());
    };

    function request() {
        return {type: orderConstants.order.GET_TRANSACTION_PAYMENT_CLEAR};
    }
};

const readyForPickup = (data, history) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order.readyForPickup(data).then((res) => {
            if (res.data.status === 'success') {
                dispatch(success(res.data.data));
                dispatch(alertActions.alert.success('Thank you. The item will be picked up shortly.'));
                history.push('/app/orders');
            } else {
                dispatch(failure(res.data.message));
                dispatch(
                    alertActions.alert.error(res.data.message?.replace(/_/g, ' '))
                );
            }
        })
            .catch((err) => {
                if (err?.response) {
                    if (err?.response.status === 401) {
                        dispatch(userActions.user.logout('seller', history));
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    } else {
                        dispatch(failure(err?.response.data.message));
                        dispatch(
                            alertActions.alert.error(
                                err?.response.data.message?.replace(/_/g, ' ')
                            )
                        );
                    }
                } else if (err?.request) {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                } else {
                    dispatch(failure(err?.message));
                    dispatch(alertActions.alert.error(err?.message?.replace(/_/g, ' ')));
                }
            });
    };

    function request() {
        return {type: orderConstants.order.ORDER_DETAILS_REQUEST};
    }

    function success(payment) {
        return {type: orderConstants.order.ORDER_DETAILS_SUCCESS, payment};
    }

    function failure(error) {
        return {type: orderConstants.order.ORDER_DETAILS_FAILURE, error};
    }
};

const providerReadyForPickup = (data, history) => {
    return (dispatch) => {
        dispatch(request());

        orderService.order.externalReadyForPickup(data).then((res) => {
            if (res.data.status === "success") {
                history.push("/login");
                dispatch(alertActions.alert.success("Thank you. The item will be picked up shortly.".replace(/_/g, " ")))
            } else {
                history.push("/login");
                dispatch(failure(res.data.message));
                dispatch(
                    alertActions.alert.error(res.data.message?.replace(/_/g, " "))
                );
            }
        })
            .catch((err) => {
                if (err.response) {
                    history.push("/login");
                    dispatch(failure(err.response.data.message));
                    dispatch(
                        alertActions.alert.error(
                            err.response.data.message?.replace(/_/g, " ")
                        )
                    );
                } else if (err.request) {
                    console.log(err.request);
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                } else {
                    console.log(err.message);
                    dispatch(failure(err.message));
                    dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
                }

                console.log(err.config);
            });
    };

    function request() {
        return {type: orderConstants.order.ORDER_DETAILS_REQUEST};
    }

    function success(payment) {
        return {type: orderConstants.order.ORDER_DETAILS_SUCCESS, payment};
    }

    function failure(error) {
        return {type: orderConstants.order.ORDER_DETAILS_FAILURE, error};
    }
};

export const order = {
    view,
    viewPayments,
    viewDisputes,
    cancelDispute,
    cancelOrder,
    assignDispatcher,
    raiseDispute,
    verifyPromo,
    clearPromo,
    processPayment,
    cancelPayment,
    processPod,
    completeOrder,
    addServiceProvider,
    getTransactionPaymentInformation,
    clearTransactionPaymentInformation,
    readyForPickup,
    providerReadyForPickup
};

